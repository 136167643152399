import { useEffect, useState } from 'react'
import papaparse, { ParseResult } from 'papaparse'
import ReportDetails from './ReportDetails'
import readGroupSheet from './readGroupSheet'
import readNightlySheet from './readNightlySheet'
import { SheetHeader } from './SheetHeader'

export const GROUP_REPORT_PREFIX = 'Group Note'
export const NIGHTLY_REPORT_PREFIX = 'Nightly Status'

function readHeader(data: any[]): SheetHeader {
	const programDateCell = (data[4][0] as string)
	const reportDateCell = (data[5][0] as string)


	const programDates = programDateCell
		.slice(programDateCell.indexOf(':') + 1)
		.split(' to ')
		.map(d => d.trim())

	const reportDates = reportDateCell
		.slice(reportDateCell.indexOf(':') + 1)
		.split(' to ')
		.map(d => d.trim())

	return {
		location: data[0][0],
		program: data[1][0],
		report: data[2][0],
		category: data[3][0],
		programDates: {
			startDate: programDates[0],
			endDate: programDates[1]
		},
		reportDates: {
			startDate: reportDates[0],
			endDate: reportDates[1]
		}
	}
}

async function readSpreadsheetFile(file: File): Promise<ParseResult<any>> {
	return new Promise(resolve => {
		papaparse.parse(file, {
			complete(results) {
				resolve(results)
			},
			dynamicTyping: true,
			delimiter: '\t'
		})
	})
}

export default function useSpreadsheets(files: File[]) {
	const [reportDetails, setReportDetails] = useState<ReportDetails | null>(null)
	const [errors, setErrors] = useState<string[]>([])

	useEffect(() => {
		let abort = false

		async function readFiles() {
			try {
				const sheets = await Promise.all(files.map(readSpreadsheetFile))

				if (abort)
					return

				const headers = sheets.map(s => readHeader(s.data))

				const errors: string[] = []

				if (headers[0].program !== headers[1].program) {
					errors.push(`Files are from different programs. [${headers[0].program}, ${headers[1].program}]`)
				}

				let dates0 = headers[0].programDates
				let dates1 = headers[1].programDates

				if (dates0.startDate !== dates1.startDate || dates0.endDate !== dates1.endDate) {
					errors.push(`Program dates don't match. [${dates0.startDate} to ${dates0.endDate}, ${dates1.startDate} to ${dates1.endDate}]`)
				}

				dates0 = headers[0].reportDates
				dates1 = headers[1].reportDates

				if (dates0.startDate !== dates1.startDate || dates0.endDate !== dates1.endDate) {
					errors.push(`Report dates don't match. [${dates0.startDate} to ${dates0.endDate}, ${dates1.startDate} to ${dates1.endDate}]`)
				}

				const groupReportIndex = headers[0].report.startsWith(GROUP_REPORT_PREFIX)
					? 0
					: headers[1].report.startsWith(GROUP_REPORT_PREFIX)
						? 1
						: -1

				const nightlyReportIndex = headers[0].report.startsWith(NIGHTLY_REPORT_PREFIX)
					? 0
					: headers[1].report.startsWith(NIGHTLY_REPORT_PREFIX)
						? 1
						: -1

				if (groupReportIndex === -1 && nightlyReportIndex === -1) {
					errors.push('Neither file has the correct report type.')
				} else if (groupReportIndex === -1) {
					errors.push('Group report file not found.')
				} else if (nightlyReportIndex === -1) {
					errors.push('Nightly report file not found.')
				}

				// Abort on errors
				if (errors.length > 0) {
					setErrors(errors)
					return
				}

				const reportDetails = new ReportDetails(headers[0])

				// Read group report
				readGroupSheet(sheets[groupReportIndex].data, reportDetails)

				// Read nightly report
				readNightlySheet(sheets[nightlyReportIndex].data, reportDetails)

				setReportDetails(reportDetails)
			} catch (e) {
				console.error('Failed to read workbooks', e)
				setErrors(['Failed to read workbooks'])
			}
		}

		if (files.length === 2) {
			readFiles().then()
		} else {
			setReportDetails(null)
			setErrors([])
		}

		return () => {
			abort = true
		}
	}, [files])

	return {
		reportDetails,
		errors
	}
}
