import React from 'react'
import { useDropzone } from 'react-dropzone'
import classes from './FileDropArea.module.scss'

interface FileDropAreaProps {
	onDrop: (files: File[]) => void
}

export default function FileDropArea({onDrop}: FileDropAreaProps) {
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

	return (
		<div {...getRootProps()} className={classes.root}>
			<input
				{...getInputProps()}
			/>
			{
				isDragActive
					? 'Drop files here'
					: 'Drag files and drop them here, or click to show file browser'
			}
		</div>
	)
}
