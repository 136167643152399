import React from 'react'
import classes from './ErrorsView.module.scss'

interface ErrorsViewProps {
	errors: string[]
}

export default function ErrorsView({errors}: ErrorsViewProps) {
	return (
		<div className={classes.root}>
			<h2>Errors</h2>
			<ul className={classes.list}>
				{errors.map((error, index) => (
					<li key={index}>
						{error}
					</li>
				))}
			</ul>
		</div>
	)
}
