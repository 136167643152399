import ReportDetails from './ReportDetails'

const START_ROW = 12 // zero-indexed

export default function readNightlySheet(data: any[], reportDetails: ReportDetails) {
	for (let index = START_ROW; index < data.length; index++) {
		const row = data[index] as (string | null)[]

		// End row padding
		if (row.length === 1 && row[0] === null)
			return

		const name = row[0] as string
		const date = row[1] as string
		const status = row[2] as string

		reportDetails.setStatus(name, date, status)
	}
}
