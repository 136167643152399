import React, { Fragment, useMemo } from 'react'
import ReportDetails from './ReportDetails'
import ReportDetailsNameGroup from './ReportDetailsNameGroup'
import classes from './ReportDetailsView.module.scss'

interface ReportDetailsViewProps {
	reportDetails: ReportDetails
}

export default function ReportDetailsView({reportDetails}: ReportDetailsViewProps) {
	const names = useMemo(() => reportDetails.getNames(), [reportDetails])

	const {header} = reportDetails

	return (
		<Fragment>
			<div className={classes.header}>
				<h2>Program <span>{header.program}</span></h2>
				<h3>Report dates <span>{header.reportDates.startDate} to {header.reportDates.endDate}</span></h3>
			</div>
			<table className={classes.table}>
				<thead>
				<tr>
					<th>Name</th>
					<th>Date</th>
					<th>Hours</th>
					<th>Status</th>
				</tr>
				</thead>

				<tbody>
				{names.map(name => (
					<ReportDetailsNameGroup
						key={name}
						name={name}
						reportDetails={reportDetails}
					/>
				))}
				</tbody>
				<tfoot>
				<tr>
					<td colSpan={4}>
						<h2>Legend</h2>
					</td>
				</tr>
				<tr>
					<td className={classes.missing}>Missing element</td>
					<td className={classes.invalid}>Invalid element</td>
					<td className={classes.mismatch}>Mismatch between Status/Hours</td>
					<td className={classes.low}>Hours below minimum billable</td>
				</tr>
				</tfoot>
			</table>
		</Fragment>
	)
}
