import React, { Fragment, useMemo } from 'react'
import ReportDetails from './ReportDetails'
import ReportDetailsRow from './ReportDetailsRow'

interface ReportDetailsNameGroupProps {
	name: string
	reportDetails: ReportDetails
}

export default function ReportDetailsNameGroup({name, reportDetails}: ReportDetailsNameGroupProps) {
	const [dateDetailsMap, dates] = useMemo(() => {
		const dateDetailsMap = reportDetails.getDateDetailsMapForName(name)
		const dates = reportDetails.getDatesForName(name, dateDetailsMap)

		return [dateDetailsMap, dates]
	}, [name, reportDetails])

	return (
		<Fragment>
			{dates.map((date, index) => {
				const details = dateDetailsMap.get(date)!

				return (
					<ReportDetailsRow
						key={`${name}-${date}`}
						name={name}
						isFirstInGroup={index === 0}
						{...details}
					/>
				)
			})}
		</Fragment>
	)
}
