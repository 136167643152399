import ReportDetails from './ReportDetails'

const START_ROW = 8 // zero-indexed
const HOURS_SLICE = 'Sum: '.length

export default function readGroupSheet(data: any[], reportDetails: ReportDetails) {
	let currentName: string | null = null
	let currentDate: string | null = null

	for (let index = START_ROW; index < data.length; index++) {
		const row = data[index] as any[]

		// End row padding
		if (row.length === 1 && row[0] === null)
			return

		const name: string | null = row[0]
		const date: string | null = row[1]
		const hours: string | null = row[2]

		if (name !== null) {
			currentName = name
		} else if (date !== null) {
			currentDate = date
		} else if (hours !== null && currentName !== null && currentDate !== null) {
			reportDetails.setHours(currentName, currentDate, Number(hours.slice(HOURS_SLICE)))
		}
	}
}
