import React from 'react'
import { DateDetails, StatusArray } from './ReportDetails'
import clsx from 'clsx'
import classes from './ReportDetailsRow.module.scss'

function checkMismatch(hours?: number, status?: string): boolean {
	// Ignore missing values for check
	if (hours === undefined || status === undefined)
		return false

	switch (status) {
		case 'P1':
			return hours < 1 || hours >= 2
		case 'P2':
			return hours < 2 || hours >= 3
		case 'P3':
			return hours < 3 || hours >= 4
		case 'P4':
			return hours < 4 || hours >= 5
		case 'P5':
			return hours < 5
		default:
			return false
	}
}

interface ReportDetailsRowProps extends DateDetails {
	name: string
	isFirstInGroup: boolean
}

export default function ReportDetailsRow({name, hours, date, status, isFirstInGroup}: ReportDetailsRowProps) {
	const hoursMissing = hours === undefined
	const hoursLow = hours !== undefined && hours < 1

	const statusMissing = status === undefined
	const statusInvalid = status !== undefined && !StatusArray.includes(status)

	const hasMismatch = checkMismatch(hours, status)

	return (
		<tr className={clsx(classes.row, {[classes.mismatch]: hasMismatch})}>
			{isFirstInGroup ? <th>{name}</th> : <td>&nbsp;</td>}
			<td>{date}</td>
			<td
				className={
					clsx({
						[classes.missing]: hoursMissing,
						[classes.low]: hoursLow
					})
				}
			>
				{hours ?? '-'}
			</td>
			<td
				className={
					clsx({
						[classes.missing]: statusMissing,
						[classes.invalid]: statusInvalid
					})
				}
			>
				{status ?? '-'}
			</td>
		</tr>
	)
}
