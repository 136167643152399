import { SheetHeader } from './SheetHeader'

export enum Status {
	P1 = 'P1',
	P2 = 'P2',
	P3 = 'P3',
	P4 = 'P4',
	P5 = 'P5'
}

export const StatusArray = Object.values(Status) as string[]

export interface DateDetails {
	date: string
	status?: string
	hours?: number
}

export type DateKey = string

export type NameKey = string

export type DateDetailsMap = Map<DateKey, DateDetails>

export type PersonDetails = Map<NameKey, DateDetailsMap>

export default class ReportDetails {
	private details: PersonDetails = new Map()
	public readonly header: SheetHeader

	public constructor(header: SheetHeader) {
		this.header = header
	}

	private getDateDetailsMap(name: string) {
		let map = this.details.get(name)
		if (map)
			return map

		map = new Map()
		this.details.set(name, map)

		return map
	}

	public setStatus(name: string, date: string, status: string) {
		const dateDetailsMap = this.getDateDetailsMap(name)

		const details = dateDetailsMap.get(date)
		if (details) {
			details.status = status
			return
		}

		dateDetailsMap.set(date, {
			date,
			status
		})
	}

	public setHours(name: string, date: string, hours: number) {
		const dateDetailsMap = this.getDateDetailsMap(name)

		const details = dateDetailsMap.get(date)
		if (details) {
			details.hours = hours
			return
		}

		dateDetailsMap.set(date, {
			date,
			hours
		})
	}

	public getNames() {
		return Array.from(this.details.keys()).sort()
	}

	public getDateDetailsMapForName(name: string) {
		const dateDetailsMap = this.details.get(name)

		if (!dateDetailsMap)
			throw new Error(`Date map not found for name [${name}]`)

		return dateDetailsMap
	}

	public getDatesForName(name: string, dateDetailsMap?: DateDetailsMap) {
		const _dateDetailsMap = dateDetailsMap ?? this.getDateDetailsMapForName(name)

		return Array.from(_dateDetailsMap.keys()).sort()
	}

	public getDetails(name: string, date: string) {
		return this.details.get(name)?.get(date)
	}
}
