import React, { Fragment, useCallback, useState } from 'react'
import FileDropArea from './FileDropArea'
import classes from './App.module.scss'
import useSpreadsheets from './useSpreadsheets'
import ErrorsView from './ErrorsView'
import ReportDetailsView from './ReportDetailsView'

const extensions = [
	'.xls',
	'.xlsx',
	'.csv'
]

export default function App() {
	const [files, setFiles] = useState<File[]>([])

	const {errors, reportDetails} = useSpreadsheets(files)

	const reset = () => {
		setFiles([])
	}

	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles.length !== 2) {
			alert('Wrong number of files! Drop two files: the group notes and the nightly report')
			return
		}

		for (let file of acceptedFiles) {
			const lowerFileName = file.name.toLowerCase()
			let isValid = false

			for (let i = 0; i < extensions.length; i++) {
				if (lowerFileName.endsWith(extensions[0])) {
					isValid = true
					break
				}
			}

			if (!isValid) {
				alert(`Not an accepted file type for [${file.name}], please try again`)
				return
			}
		}

		setFiles(acceptedFiles)
	}, [])

	return (
		<div className={classes.root}>
			{reportDetails || errors.length > 0 ? (
				<Fragment>
					{reportDetails ? <ReportDetailsView reportDetails={reportDetails}/> : <ErrorsView errors={errors}/>}
					<button onClick={reset} className={classes.resetButton}>
						Reset
					</button>
				</Fragment>
			) : (
				<FileDropArea onDrop={onDrop}/>
			)}
		</div>
	)
}
